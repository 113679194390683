.preference-main {
    margin-top: 70px;
    padding: 40px;

    .pagetitle h1 {
        font-size: 32px;
        font-weight: 400;
        color: var(--app-theme-color);
    }

    .txt-medium {
        font-weight: 500
    }

    /** Table **/
    .tableCustom {

		thead {
			border-top: #ddd solid 1px
		}

		tr {

			th,
			td {
				border-color: #ddd;
				padding-top: 20px;
				padding-bottom: 20px;
				font-size: 14px;
			}
		}

        .small{
            font-size:small;
        }

		tbody tr:hover td {
			background: #F4F4F4 !Important
		}
        .btn-icon {
            padding: 0px;
            min-width: inherit
        }
    
        .btn-primary-w {
            background-color: #fff;
            border-color: #fff;
        }

		tbody {
			.buttons {
				.edit-btn {
					color: var(--app-theme-color);
				}

				.delete-btn {
					color: var(--app-theme-color);
				}
			}
		}

		thead tr th.center,
		tbody tr td.center {
			text-align: center;
		}
	}

    .submit-footer {
        font-size: 18px;
        bottom: 0;
        padding: 0.75rem 2.5rem;

        .submit {
            .submit-btn {
                font-size: 18px;
                background-color: var(--app-secondary-color);
                border: 1px solid var(--app-secondary-color);
            }

            .submit-btn:hover {
                font-size: 18px;
                background-color: var(--app-theme-color);
                border: 1px solid var(--app-theme-color);
            }
        }
    }

    // modal 
    .modal-dialog {
        .modal-content {
            border-radius: 8px;
            box-shadow: 0px 0px 12px rgb(0 0 0 / 20%);
            padding: 40px;

            .modal-header {
                padding: 0 0 40px 0;
                border: 0;

                .modal-title {
                    font-size: 26px
                }

                .btn-close {
                    font-size: 48px;
                    color: #aeaeae
                }
            }

            .modal-body {
                padding: 0px;
            }
            .small{
                font-size:small;
            }
            .modal-footer {
                padding: 40px 0px 0px 0px;
                border: 0;

                .submit-btn {
                    background-color: var(--app-secondary-color);
                    color: white;
                }

                .submit-btn:hover {
                    background-color: var(--app-theme-color);
                    color: white;
                }

                .cancel-btn {
                    color: black;
                    box-shadow: none;
                }

                .cancel-btn:hover {
                    background-color: var(--app-theme-color);
                    color: white;
                }
            }
        }
    }


    @media only screen and (min-width: 576px) {

        #editfavourite .modal-dialog,
        #assignproject .modal-dialog {
            max-width: 600px;
        }
    }
}

.btn-primary {
    background-color: var(--app-secondary-color);
    border-color: var(--app-secondary-color);
}

.btn-transparent {
    color: #000;
    background-color: transparent;
    border-color: transparent;
    box-shadow: none
}

.btn-lg {
    font-size: 16px;
    padding: 11px 30px;
    min-width: 150px
}

// .btn {
// 	font-size: 14px;
// 	padding: 6px 12px;
// 	min-width: 100px
// }

.btn {
    border-radius: 4px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
    font-weight: 500;
    background-color: #fff;
    border-color: #fff;
}