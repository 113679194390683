.user-main {
    margin-top: 70px;
    padding: 40px;

    .textgreen {
        color: #2B7E22;
    }

    .textyellow {
        color: #ED9D02;
    }

    .textred {
        color: #D54242;
    }

    .bordergreen {
        border: 1px solid #2B7E22;
    }

    .borderyellow {
        border: 1px solid #ED9D02;
    }

    .borderred {
        border: 1px solid #D54242;
    }

    .pagetitle h1 {
        font-size: 32px;
        font-weight: 400;
        color: var(--app-theme-color);
    }


    .add-btn {
        color: white;
        background-color: var(--app-secondary-color);
        border: 1px solid var(--app-secondary-color);
    }

    .add-btn:hover {
        color: white;
        background-color: var(--app-theme-color);
        border: 1px solid var(--app-theme-color);
    }

    .section {



        /** Table **/
        .tableCustom {

            thead {
                border-top: #ddd solid 1px
            }

            tr {

                th,
                td {
                    border-color: #ddd;
                    padding-top: 20px;
                    padding-bottom: 20px;
                    font-size: 14px;
                }
            }

            tbody tr:hover td {
                background: #F4F4F4 !Important
            }

            thead tr th.center,
            tbody tr td.center {
                text-align: center
            }

            .table-body {
                .table-row {

                    .col-status {
                        .btn-outline-success {
                            background-color: transparent;
                            border: 1px solid green;
                            color: green;
                        }

                        .btn-outline-success:hover {
                            background-color: green;
                            border: 1px solid green;
                            color: white;
                        }

                        .btn-outline-danger {
                            background-color: transparent;
                            border: 1px solid red;
                            color: red;
                        }

                        .btn-outline-danger:hover {
                            background-color: red;
                            border: 1px solid red;
                            color: white;
                        }
                    }

                    .col-role {
                        .btn-outline-success {
                            background-color: transparent;
                            border: 1px solid green;
                            color: green;
                        }

                        .btn-outline-success:hover {
                            background-color: green;
                            border: 1px solid green;
                            color: white;
                        }

                        .btn-outline-danger {
                            background-color: transparent;
                            border: 1px solid red;
                            color: red;
                        }

                        .btn-outline-danger:hover {
                            background-color: red;
                            border: 1px solid red;
                            color: white;
                        }
                    }

                    .normal-btn {
                        border: 1px solid #2B7E22;
                        color: #2B7E22;
                        cursor: default;
                    }

                    .btn-primary-w {
                        background-color: #fff;
                        border-color: #fff;
                    }

                    .btn-icon {
                        padding: 0px;
                        min-width: inherit;

                        .user-btn,
                        .edit-btn {
                            fill: var(--app-theme-color);
                        }
                    }

                    .form-check {
                        display: flex;
                        flex-flow: row-reverse;
                    }

                    .form-switch .form-check-input {
                        width: 2.8em;
                        margin-right: 2px;
                        height: 1.6rem;
                    }

                    .form-check-input:checked {
                        background-color: #004195;
                        border-color: #004195;
                    }
                }
            }
        }
    }

    .txt-medium {
        font-weight: 500
    }

    // user-info modal
    .userinfo {
        .user-details {
            .details {
                .designation {
                    color: #aeaeae;
                }

                .email {
                    .txt-black {
                        color: #000;
                    }

                    .txt-black:hover {
                        color: #717ff5;
                        text-decoration: none;
                    }
                }
            }
        }

        .view {
            .status-toggle {
                .form-check-input {
                    width: 2.8em;
                    margin-left: -2.5em;
                    height: 1.6rem;
                }

                .form-check-input:checked {
                    background-color: #2B8635 !important;
                    border-color: #2B8635 !important;
                    width: 2.8em;
                    margin-left: -2.5em;
                    height: 1.6rem;
                }
            }

            .role-row {
                .btn-outline-danger {
                    border: 1px solid red;
                    background-color: transparent;
                    color: red;
                    cursor: default;
                }

                .btn-outline-success {
                    border: 1px solid green;
                    background-color: transparent;
                    color: green;
                    cursor: default;
                }
            }

            .status {
                font-size: 14px;
                padding: 6px 12px;
                min-width: 100px;
                border-radius: 4px;
                color: #004195;
                border: #004195 solid 1px;
                display: inline-block;
                text-align: center;
            }

            .red {
                color: #D54242;
                border-color: #D54242;
            }

            .green {
                color: #2B7E22;
                border-color: #2B7E22;
            }
        }

        .modal-footer {
            .edit-btn {
                background-color: var(--app-secondary-color);
            }

            .edit-btn:hover {
                background-color: var(--app-theme-color);
            }
        }
    }

    // edit-info modal
    .editinfo {
        .user-details {
            .details {
                .designation {
                    color: #aeaeae;
                }

                .email {
                    .txt-black {
                        color: #000;
                    }

                    .txt-black:hover {
                        color: #717ff5;
                        text-decoration: none;
                    }
                }
            }
        }

        .edit {
            .status-toggle {
                .form-check-input {
                    width: 2.8em;
                    margin-left: -2.5em;
                    height: 1.6rem;
                }

                .form-check-input:checked {
                    background-color: #2B8635 !important;
                    border-color: #2B8635 !important;
                    width: 2.8em;
                    margin-left: -2.5em;
                    height: 1.6rem;
                }
            }

            .role {
                .form-check-input:checked {
                    background-color: #2B8635;
                    border-color: #2B8635;
                }
            }

            .employment {
                .form-check-input:checked {
                    background-color: #2B8635;
                    border-color: #2B8635;
                }
            }
        }

        .modal-footer {
            .cancel-btn {
                color: #000;
                background-color: transparent;
                border-color: transparent;
                box-shadow: none !important;
            }

            .cancel-btn:hover {
                color: #000 !important;
                background-color: transparent !important;
                border-color: transparent !important;
                box-shadow: none !important;
            }

            .update-btn {
                background-color: var(--app-secondary-color);
            }

            .update-btn:hover {
                background-color: var(--app-theme-color);
            }
        }
    }

    /** modal **/
    .modal-dialog {
        .modal-content {
            border-radius: 8px;
            box-shadow: 0px 0px 12px rgb(0 0 0 / 20%);
            padding: 40px;

            hr {
                opacity: 0.25;
                background: #E2E2E2 !important;
            }

            .modal-header {
                padding: 0 0 40px 0;
                border: 0;

                .modal-title {
                    font-size: 26px
                }

                .btn-close {
                    font-size: 48px;
                    color: #aeaeae
                }
            }

            .modal-body {
                padding: 0px;
            }

            .modal-footer {
                padding: 40px 0px 0px 0px;
                border: 0;

                button {
                    margin: 0;
                }

                .btn-full {
                    width: 100% ! Important;
                }

                .submit-btn {
                    background-color: var(--app-secondary-color);
                    color: white;
                }

                .submit-btn:hover {
                    background-color: var(--app-theme-color);
                    color: white;
                }

                .cancel-btn {
                    color: black;
                    box-shadow: none;
                }

                .cancel-btn:hover {
                    background-color: var(--app-theme-color);
                    color: white;
                }
            }
        }
    }

    @media only screen and (min-width: 576px) {

        #editproject .modal-dialog,
        #assignproject .modal-dialog {
            max-width: 600px;
        }
    }
}