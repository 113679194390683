.import-timesheet {
    .import-main {
        margin-top: 70px;
        padding: 40px;

        .btns-div {
            .import-div {
                .import-btn {
                    button {
                        background-color: var(--app-theme-color);
                        border: none;
                    }
                }
            }

            .save-sbmt-div {
                .save-btn {
                    button {
                        background-color: var(--app-secondary-color);
                        color: white;
                    }

                    button:hover {
                        background-color: var(--app-theme-color) !important;
                        color: white !important;
                    }
                }

                .submit-btn {
                    button {
                        background-color: var(--app-secondary-color);
                        color: white;
                    }

                    button:hover {
                        background-color: var(--app-theme-color) !important;
                        color: white !important;
                    }
                }
            }
        }

        .userTable {
            .userHeader {
                .header-arrow-btn {
                    width: 8% !important;
                    min-width: 16px !important;
                    padding: 2px;

                    button {
                        height: 38px;
                        width: 38px;

                        img {
                            color: var(--app-theme-color);
                        }
                    }

                    .btn-border {
                        border: #FF5F00 solid 1px ! Important;
                        transform: rotate(3.142rad);
                    }
                }

                .user-profile {
                    .profile-width {
                        max-width: 40px;
                        max-height: 40px;
                    }
                }
            }

            .table-row {
                background-color: transparent !important;

                td {
                    background-color: transparent !important;
                }

                td:hover {
                    background-color: transparent !important;
                }

                .divApproval {
                    background-color: transparent !important;

                    .tableCustom {
                        background-color: transparent !important;
                        margin-bottom: 0;

                        .table-date-header {
                            .header-row {
                                .week {
                                    display: block;
                                }
                            }
                        }
                    }

                    .tableCustom:hover {
                        background-color: transparent !important;
                        margin-bottom: 0;
                    }

                    .table-date-body {
                        background-color: transparent !important;

                        .seq-row {
                            .body-project-col {
                                .project-div {
                                    padding: 4px 2px !important;
                                    background: #e7e6e6;
                                    border: 1px;
                                    min-height: 51px;
                                    border-radius: 5px;

                                    .project-display {
                                        font-size: 14px;
                                        font-weight: 600 !important;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        white-space: nowrap;
                                        width: 75%;
                                    }

                                    .comments-display {
                                        font-size: 9px;
                                        line-height: 11px;
                                        display: -webkit-box;
                                        -webkit-line-clamp: 2;
                                        -webkit-box-orient: vertical;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        white-space: nowrap;
                                        width: 20rem;
                                    }
                                }
                            }

                            .body-project-col:hover {
                                background-color: #e7e6e6;
                            }
                        }
                    }

                    .table-date-body {
                        background-color: transparent !important;
                    }
                }

                .divApproval:hover {
                    background-color: transparent !important;
                }
            }

            .table-row:hover {
                background-color: transparent !important;
            }
        }

        .lock-btn {
            background-color: red;
            color: white;
            border: none;
            width: max-content;
        }

        // .tableHours tbody tr td :nth-child(9) {
        //     width: 16px;
        //     min-width: 0px !important;
        //     padding: 2px;
        //     justify-content: flex-end;
        // }

        .tableHours tbody tr .body-project-col {
            width: 25% !important;
            padding: 2px;
        }

        .tableHours tbody tr td:last-child {
            width: 20px !important;
            min-width: 0px !important;
            padding: 2px;
            justify-content: flex-end;
        }
    }

    .error-Modal {
        .modal-dialog {
            display: flex;
            height: auto;
            margin: auto;
            max-width: 100%;
            margin-right: 0;
            margin-left: 0;

            .modal-custom-content {
                width: auto;
                border-radius: 8px;
                box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.2);
                padding: 40px;

                table,
                th,
                td {
                    border: 1px solid black;
                    border-collapse: collapse;
                    background-color: white;
                }

                .modal-custom-header {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    border: 0;

                    .btn-close {
                        margin: auto -7px 0px auto;
                        font-size: 54px;
                        color: #aeaeae;
                        padding: 17px 0px 0px 0px;
                    }
                }

                .table {
                    .tHead {}

                    .tBody {}
                }

                .btn-div {
                    .save-btn {
                        background-color: green;
                        color: white;
                        padding: 13px 20px;
                    }
                }
            }
        }
    }
}