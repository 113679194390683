.dashboard-main {
    margin-top: 70px;
    padding: 40px;

    .pagetitle h1 {
        font-size: 32px;
        font-weight: 400;
        color: var(--app-theme-color);
    }

    .section {
        .table-header-title {
            font-size: 13px;
        }

        .calendar-header {
            display: flex;
            align-items: center;
            gap: 1rem;

            .calendar {
                width: auto;
                background-color: white;
                border: 1px solid #ced4da;
                border-radius: 5px;

                .btn-icon-left {
                    border-right: 1px solid #ced4da;
                    background-color: #ced4da !important;
                    width: 2.5rem;
                    height: 2.5rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    box-shadow: none;
                    border: none;
                }

                .btn-icon-right {
                    border-right: 1px solid #ced4da;
                    background-color: #ced4da !important;
                    width: 2.5rem;
                    height: 2.5rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    box-shadow: none;
                    border: none;
                }
            }

            .calendar-dropdown {
                .react-datepicker__input-container {
                    display: block;
                }

                .react-datepicker {
                    width: 225px;
                    font-family: inherit;
                    font-size: inherit;
                    background-color: #fff;
                    color: #000;
                    border: none;
                    border-radius: 0.3rem;
                    display: inline-block;
                    position: relative;
                    line-height: initial;

                }

                .react-datepicker__year--container {
                    width: 225px;
                }

                .react-datepicker__month-container {
                    float: none;
                }

                .react-datepicker__year {
                    margin: 1.4rem;
                    text-align: center;
                }

                .react-datepicker__year-wrapper {
                    display: flex;
                    flex-wrap: wrap;
                    max-width: 300px;
                    margin: -27px 0px -21px 0px;
                }

                .react-datepicker__year-text--selected {
                    border-radius: 0.3rem;
                    background-color: #ff5f00;
                    color: #fff;
                }

                .react-datepicker__navigation--previous {
                    left: -3px;
                    margin: 5px -6px;
                }

                .react-datepicker__navigation--next {
                    right: 2px;
                    margin: 5px -6px;
                }

                .react-datepicker__header {
                    padding-top: 9px;
                    text-align: center;
                    background-color: white;
                    border-bottom: none;
                    border-top-left-radius: 0.3rem;
                    // padding: 4px 0;
                    position: relative;
                    width: 225px;
                    color: black;
                }

                .react-datepicker-popper {
                    .react-datepicker__year .react-datepicker__year-text {
                        display: block;
                        width: 4rem;
                        margin: 4px;
                        padding: 11px 14px;
                    }
                }
            }

            .cal-icon {
                .cal-btn {
                    background-color: white !important;
                    border: 1px solid #ced4da;
                    width: 2.5rem;
                    height: 2.5rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    i {
                        color: var(--app-theme-color);
                    }
                }

                .cal-btn:active {
                    border: 1px solid #ced4da;
                }
            }

            .my-employees,
            .my-timesheets {
                .active {
                    background-color: var(--app-theme-color);
                    color: white;
                }

                button {
                    background-color: white;
                }
            }

            .form-switch {
                padding: 0;

                .form-check-input {
                    width: 2.75rem;
                    height: 1.5rem;
                    border: 1px solid grey;
                    box-shadow: none;
                }

                .form-check-input:checked {
                    background-color: var(--app-theme-color);
                    border: 1px solid var(--app-theme-color);
                    box-shadow: none;
                }
            }
        }

        .drop-down-section {

            // .function-select,
            // .cate-select,
            // .disci-select,
            // .project-select,
            // .manager-employees {
            //     width: 200px;
            //     max-width: 200px;
            //     min-width: 200px;
            // }

            .manager-employees {
                .form-control {
                    .css-13cymwt-control {
                        height: 40px;
                    }
                }
            }

            .clear-select {
                .btn {
                    height: 40px;
                    color: red;
                    background-color: white;
                    // font-size: 20px;
                }

                .btn:hover {
                    background-color: white;
                    border: 1px solid red;
                }
            }
        }

        .userTable {
            .userHeader {
                .header-arrow-btn {
                    padding: 2px;

                    button {
                        height: 38px;
                        width: 38px;

                        img {
                            color: var(--app-theme-color);
                        }
                    }

                    .btn-border {
                        border: #FF5F00 solid 1px ! Important;
                        transform: rotate(3.142rad);
                    }
                }

                .user-profile {
                    .profile-width {
                        max-width: 40px;
                        max-height: 40px;
                    }
                }
            }

            .table-row {
                background-color: transparent !important;

                td {
                    background-color: transparent !important;
                }

                td:hover {
                    background-color: transparent !important;
                }

                .divApproval {
                    background-color: transparent !important;

                    .tableCustom {
                        background-color: transparent !important;
                        margin-bottom: 0;

                        .table-date-header {
                            .header-row {
                                .week {
                                    display: block;
                                }
                            }
                        }
                    }

                    .tableCustom:hover {
                        background-color: transparent !important;
                        margin-bottom: 0;
                    }

                    .table-date-body {
                        background-color: transparent !important;

                        .seq-row {
                            border-top: 1px solid #c9c8c8;
                            border-bottom: 1px solid #c9c8c8;

                            .body-project-col {
                                .project-div {
                                    padding: 4px 2px !important;
                                    // background: #e7e6e6;
                                    border: 1px;
                                    min-height: 51px;
                                    border-radius: 5px;

                                    .project-display {
                                        font-size: 13px;
                                        font-weight: 600 !important;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        white-space: nowrap;
                                        width: 75%;
                                    }

                                    .comments-display {
                                        font-size: 11px;
                                        line-height: 11px;
                                        display: -webkit-box;
                                        -webkit-line-clamp: 2;
                                        -webkit-box-orient: vertical;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        white-space: nowrap;
                                        width: 20rem;
                                    }
                                }
                            }

                            .body-project-col:hover {
                                background-color: #e7e6e6;
                            }
                        }
                    }

                    .table-date-body {
                        background-color: transparent !important;
                    }
                }

                .divApproval:hover {
                    background-color: transparent !important;
                }
            }

            .table-row:hover {
                background-color: transparent !important;
            }
        }
    }

    .txt-medium {
        font-weight: 500
    }

    .btn-icon {
        padding: 0px;
        min-width: inherit
    }

    .btn-primary-w {
        background-color: #fff;
        border-color: #fff;
    }

    /** Table **/
    .tableCustom {
        tr {

            th,
            td {
                border-color: #ddd;
                padding-top: 20px;
                padding-bottom: 20px;
                font-size: 14px;
            }
        }

        tbody tr:hover td {
            background: #F4F4F4 !Important
        }

        tbody {
            .row {
                .project-details {
                    .project-display {
                        font-size: 13px;
                        font-weight: 600 !important;
                    }

                    .comments-display {
                        font-size: 11px;
                        line-height: 11px;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                    }
                }
            }

            .total-month-hours {
                div {
                    color: var(--app-theme-color);
                }
            }
        }

        thead tr th.center,
        tbody tr td.center {
            text-align: center
        }
    }
}

.btn-primary {
    background-color: var(--app-secondary-color)FF5F00;
    border-color: var(--app-secondary-color);
}

.btn-transparent {
    color: #000;
    background-color: transparent;
    border-color: transparent;
    box-shadow: none
}

.btn-lg {
    font-size: 16px;
    padding: 11px 30px;
    min-width: 150px
}

.btn {
    border-radius: 4px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
    font-weight: 500
}

.form-select {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}