.sidebar {
  top: 70px;
  padding: 40px 20px 20px 20px;

  .sidebar-nav {
    li.nav-item:last-child .nav-link {
      border-bottom: 0;
    }

    .nav-item {
      margin-bottom: 0px;
      margin-left: 15px;
      margin-right: 15px;

      .icon-report,
      .icon-period {
        height: 23px;
        width: 23px;
        margin-left: 5px;
      }
    }

    .nav-link {
      font-weight: 400;
      color: var(--app-secondary-color);
      padding: 15px 0;
      background: transparent;
      border-bottom: #eaeaea solid 1px;
      font-size: 14px;
      cursor: pointer;

      i {
        margin-right: 18px;
      }
    }

    .li-border {
      border-top: #eaeaea solid 1px;
      border-bottom: #eaeaea solid 1px;
    }

    .nav-link.collapsed {
      color: #aeaeae;
      border-bottom: #eaeaea solid 1px;
      padding: 15px 0;
      font-size: 14px;
      font-weight: 400;

      i {
        -webkit-filter: grayscale(100%);
        filter: grayscale(100%);
        opacity: 0.4;
      }
    }

    .nav-link:hover {
      color: var(--app-secondary-color);
      background: none;
    }

    .nav-link>i.m-0 {
      margin-right: 0px;
    }

    .nav-content {
      padding: 0;

      a:hover,
      a.active,
      a:focus {
        color: var(--app-secondary-color);
      }

      a {
        font-weight: 400;
        color: #aeaeae;
        padding: 15px 0 15px 53px;
        border-top: #eaeaea solid 1px;
      }
    }
  }
}