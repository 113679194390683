.approval-status {
    .approval-main {
        margin-top: 70px;
        padding: 40px;

        .pagetitle {
            margin-bottom: 40px;

            .pagetitle h1 {
                font-size: 24px;
                margin-bottom: 0;
                font-weight: 600;
                color: var(--app-theme-color);
            }
        }

        .calendar-header {
            display: flex;
            align-items: center;
            gap: 1rem;

            .calendar {
                width: auto;
                background-color: white;
                border: 1px solid #ced4da;
                border-radius: 5px;

                .fa-chevron-left,
                .fa-chevron-right {
                    color: var(--app-theme-color);
                }

                .btn-icon-left,
                .btn-icon-right {
                    background-color: #ced4da !important;
                    width: 2.5rem;
                    height: 2.5rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    box-shadow: none;
                    border: none;

                    i {
                        color: var(--app-theme-color);
                    }
                }

                .btn-icon-left {
                    border-right: 1px solid #ced4da;
                }

                .btn-icon-right {
                    border-left: 1px solid #ced4da;
                }
            }

            .cal-icon {
                .cal-btn {
                    background-color: white !important;
                    border: 1px solid #ced4da;
                    width: 2.5rem;
                    height: 2.5rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    i {
                        color: var(--app-theme-color);
                    }
                }

                .cal-btn:active {
                    border: 1px solid #ced4da;
                }


            }

            .home-btn {
                background-color: white !important;
                border: 1px solid #ced4da;
                width: 2.5rem;
                height: 2.5rem;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .home-btn:active {
                border: 1px solid #ced4da;
            }

            .react-datepicker__input-container {
                display: block;
            }
        }

        .userTable {
            .userHeader {
                .header-arrow-btn {
                    width: 8% !important;
                    min-width: 16px !important;
                    padding: 2px;

                    button {
                        height: 38px;
                        width: 38px;

                        img {
                            color: var(--app-theme-color);
                        }
                    }

                    .btn-border {
                        border: #FF5F00 solid 1px ! Important;
                        transform: rotate(3.142rad);
                    }
                }
            }

            .table-row {
                background-color: transparent !important;

                td {
                    background-color: transparent !important;
                }

                td:hover {
                    background-color: transparent !important;
                }

                .divApproval {
                    background-color: transparent !important;

                    .tableCustom {
                        background-color: transparent !important;
                        margin-bottom: 0;

                        .table-date-header {
                            .header-row {
                                .week {
                                    display: block;
                                }
                            }
                        }
                    }

                    .tableCustom:hover {
                        background-color: transparent !important;
                        margin-bottom: 0;
                    }

                    .table-date-body {
                        background-color: transparent !important;

                        .seq-row {
                            .body-project-col {
                                .project-div {
                                    padding: 4px 2px !important;
                                    background: #e7e6e6;
                                    border: 1px;
                                    min-height: 51px;
                                    border-radius: 5px;

                                    .project-display {
                                        font-size: 14px;
                                        font-weight: 600 !important;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        white-space: nowrap;
                                        width: 75%;
                                    }

                                    .comments-display {
                                        font-size: 9px;
                                        line-height: 11px;
                                        display: -webkit-box;
                                        -webkit-line-clamp: 2;
                                        -webkit-box-orient: vertical;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        white-space: nowrap;
                                        width: 20rem;
                                    }
                                }
                            }

                            .body-project-col:hover {
                                background-color: #e7e6e6;
                            }
                        }
                    }

                    .table-date-body {
                        background-color: transparent !important;
                    }
                }

                .divApproval:hover {
                    background-color: transparent !important;
                }
            }

            .table-row:hover {
                background-color: transparent !important;
            }
        }

        .profile-width {
            max-width: 40px;
            max-height: 40px;
        }

        .lock-btn {
            background-color: red;
            color: white;
            border: none;
            width: max-content;
        }

        .tableHours tbody tr td :nth-child(9) {
            width: 16px;
            min-width: 0px !important;
            padding: 2px;
            justify-content: flex-end;
        }

        .tableHours tbody tr .body-project-col {
            width: 30% !important;
            padding: 2px;
        }

        .tableHours tbody tr td:last-child {
            width: 20px !important;
            min-width: 0px !important;
            padding: 2px;
            justify-content: flex-end;
        }

        .pagination-nav {
            .pagination {
                .page-item {
                    .page-link {
                        // border: 1px solid var(--app-theme-color);
                        cursor: pointer;
                    }
                }
            }
        }
    }
}