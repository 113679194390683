.holidays-main {
    margin-top: 70px;
    padding: 40px;

    .pagetitle h1 {
        font-size: 32px;
        font-weight: 400;
        color: var(--app-theme-color);
        margin-bottom: 20px;
    }

    .section {
        .react-datepicker {
            width: 338px;
            font-family: inherit;
            font-size: inherit;
            background-color: #fff;
            color: #000;
            border: none;
            border-radius: 0.3rem;
            display: inline-block;
            position: relative;
            line-height: initial;

            .react-datepicker__year--container {
                width: 350px;
            }
        }

        .react-datepicker__year {
            margin: 1.4rem;
            text-align: center;
        }

        .react-datepicker__year-wrapper {
            display: flex;
            flex-wrap: wrap;
            max-width: 300px;
            margin: -27px 0px -21px 0px;
        }

        .react-datepicker__year-text--selected {
            border-radius: 0.3rem;
            background-color: #ff5f00;
            color: #fff;
        }

        .react-datepicker__navigation--previous {
            left: -3px;
            margin: 5px -6px;
        }

        .react-datepicker__navigation--next {
            right: 2px;
            margin: 5px -6px;
        }

        .react-datepicker__header {
            padding-top: 9px;
            text-align: center;
            background-color: white;
            border-bottom: none;
            border-top-left-radius: 0.3rem;
            // padding: 4px 0;
            position: relative;
            width: 338px;
            color: black;
        }

        .react-datepicker-popper {
            .react-datepicker__year .react-datepicker__year-text {
                display: block;
                width: 4rem;
                margin: 4px;
                padding: 11px 14px;
            }
        }

        .add-btn {
            color: white;
            background-color: var(--app-secondary-color);
            border: 1px solid var(--app-secondary-color);
        }

        .tableCustom {
            .table-body {
                .btn-outline-success {
                    color: green;
                    border: 1px solid green;
                    background-color: transparent;
                }

                .btn-outline-success:hover {
                    background-color: green;
                    border: 1px solid green;
                    color: #fff;
                }

                .btn-outline-danger {
                    color: red;
                    border: 1px solid red;
                    background-color: transparent;
                }

                .btn-outline-danger:hover {
                    background-color: red;
                    border: 1px solid red;
                    color: #fff;
                }

                .edit-btn {
                    color: var(--app-theme-color);
                    background-color: white;
                }

                .edit-btn:hover {
                    color: var(--app-theme-color);
                    background-color: white;
                }

                .delete-btn {
                    color: var(--app-theme-color);
                    background-color: white;
                }

                .delete-btn:hover {
                    color: var(--app-theme-color);
                    background-color: white;
                }
            }
        }
    }

    // Modal 
    .modal-dialog {
        .modal-content {
            border-radius: 8px;
            box-shadow: 0px 0px 12px rgb(0 0 0 / 20%);
            padding: 40px;

            .modal-header {
                padding: 0 0 40px 0;
                border: 0;

                .modal-title {
                    font-size: 26px
                }

                .btn-close {
                    font-size: 48px;
                    color: #aeaeae
                }
            }

            .modal-body {
                padding: 0px;

                .react-datepicker-wrapper {
                    display: block;
                }
            }

            .modal-footer {
                padding: 40px 0px 0px 0px;
                border: 0;

                .submit-btn {
                    background-color: var(--app-secondary-color);
                    color: white;
                }

                .submit-btn:hover {
                    background-color: var(--app-theme-color);
                    color: white;
                }

                .cancel-btn {
                    color: black;
                    box-shadow: none;
                }

                .cancel-btn:hover {
                    background-color: var(--app-theme-color);
                    color: white;
                }
            }
        }
    }

}