body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  --app-theme-color: #006271 !important;
  --app-theme-50-color: #48a5b3;
  --app-theme-25-color: #7fb3bb;

  --app-secondary-color: #ff5f00;
  --app-secondary-50-color: #fa823d;
  --app-secondary-25-color: #ffab7a;

  .app-theme-bg-color {
    background-color: var(--app-theme-color);
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}