.timesheet {
    .timesheet-main {
        margin-top: 70px;
        padding: 40px;

        .pagetitle {
            margin-bottom: 40px;

            .pagetitle h1 {
                font-size: 24px;
                margin-bottom: 0;
                font-weight: 600;
                color: var(--app-theme-color);
            }
        }

        @media only screen and (max-width: 480px) {
            .pagetitle {
                text-align: center;

                .pagetitle h1 {
                    font-size: 20px;
                }
            }
        }

        .calendar-header {
            display: flex;
            align-items: center;
            gap: 1rem;

            .calendar {
                width: auto;
                background-color: white;
                border: 1px solid #ced4da;
                border-radius: 5px;

                .fa-chevron-left,
                .fa-chevron-right {
                    color: var(--app-theme-color);
                }

                .btn-icon-left,
                .btn-icon-right {
                    background-color: #ced4da !important;
                    width: 2.5rem;
                    height: 2.5rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    i {
                        color: var(--app-theme-color);
                    }
                }

                .btn-icon-left {
                    border-right: 1px solid #ced4da;
                }

                .btn-icon-right {
                    border-left: 1px solid #ced4da;
                }
            }

            .cal-icon {
                .cal-btn {
                    background-color: white !important;
                    border: 1px solid #ced4da;
                    width: 2.5rem;
                    height: 2.5rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    i {
                        color: var(--app-theme-color);
                    }
                }

                .cal-btn:active {
                    border: 1px solid #ced4da;
                }
            }

            .react-datepicker__input-container {
                display: block;
            }
        }

        @media only screen and (max-width: 480px) {
            .calendar-header {
                justify-content: center;

                .calendar {
                    background-color: transparent;
                    border: none;

                    .btn-icon-left,
                    .btn-icon-right {
                        border-radius: 50% !important;
                        height: 51.6px;
                        width: 51.6px;
                        background-color: #F9F9F9 !important;
                        border: 1px solid #EAEAEA;
                    }

                    h6 {
                        font-size: 18px;
                        background: transparent;
                        box-shadow: none;
                        font-weight: 500 !important;
                    }
                }

                .react-datepicker-wrapper {
                    display: none;
                }
            }
        }

        .custom-table {

            .tbl-header,
            .tbl-row .tbl-date {
                display: flex;
                width: 100%;
            }

            .tbl-header {
                [class^="col-"] {
                    padding-right: 5px;
                }

                .col-Date {
                    width: 8%;
                }

                .col-Hours {
                    width: 8%;
                }

                .col-Project {
                    width: 16%;
                }

                .col-Category {
                    width: 16%;
                }

                .col-Function {
                    width: 16%;
                }

                .col-Discipline {
                    width: 16%;
                }

                .col-Comment {
                    width: 20%;
                }

                .col-Delete {
                    width: 39px;
                    min-width: 39px;
                }
            }

            .tbl-row {
                width: 100%;

                .tbl-date {
                    [class^="input-col"] {
                        padding-right: 5px;
                    }

                    .d-flex {
                        .error {
                            border: 1px solid red;
                        }

                        .input-col-Date {
                            width: 8%;
                        }

                        .input-col-Hours {
                            width: 8%;

                            .label {
                                display: none;
                            }

                            input::-webkit-outer-spin-button,
                            input::-webkit-inner-spin-button {
                                -webkit-appearance: none;
                                margin: 0;
                            }

                            /* Firefox */
                            input[type=number] {
                                -moz-appearance: textfield;
                            }
                        }

                        .input-col-Project {
                            width: 16%;

                            .label {
                                display: none;
                            }

                            .form-select {
                                text-overflow: ellipsis;
                                overflow: hidden;
                                white-space: nowrap;
                            }

                            .form-control {
                                .css-1rhw8h9-control {
                                    margin-top: 0;
                                }

                                .css-8iba47-control {
                                    margin-top: 0;
                                }
                            }
                        }

                        .input-col-Category {
                            width: 16%;

                            .label {
                                display: none;
                            }

                            .form-select {
                                text-overflow: ellipsis;
                                overflow: hidden;
                                white-space: nowrap;
                            }

                            .form-control {
                                .css-1rhw8h9-control {
                                    margin-top: 0;
                                }

                                .css-8iba47-control {
                                    margin-top: 0;
                                }
                            }
                        }

                        .input-col-Function {
                            width: 16%;

                            .label {
                                display: none;
                            }

                            .form-select {
                                text-overflow: ellipsis;
                                overflow: hidden;
                                white-space: nowrap;
                            }

                        }

                        .form-control {
                            .css-1rhw8h9-control {
                                margin-top: 0;
                            }

                            .css-8iba47-control {
                                margin-top: 0;
                            }
                        }

                        .input-col-Discipline {
                            width: 16%;

                            .label {
                                display: none;
                            }

                            .form-select {
                                text-overflow: ellipsis;
                                overflow: hidden;
                                white-space: nowrap;
                            }

                            .form-control {
                                .css-1rhw8h9-control {
                                    margin-top: 0;
                                }

                                .css-8iba47-control {
                                    margin-top: 0;
                                }
                            }
                        }

                        .input-col-Comment {
                            width: 20%;

                            .label {
                                display: none;
                            }
                        }
                    }

                    .icon-col-Delete {
                        width: 39px;
                        min-width: 39px;
                        cursor: pointer;

                        .delete-btn {
                            background-color: white;
                            color: var(--app-theme-color);
                        }

                        .delete-btn:hover {
                            background-color: var(--app-theme-color);
                            color: white;
                        }

                        .delete-btn:disabled {
                            background-color: #aeaeae;
                            color: grey;
                            cursor: default !important;
                        }

                        .delete-btn:disabled:hover {
                            background-color: #aeaeae;
                            color: grey;
                            cursor: default !important;
                        }
                    }
                }

                .row-footer {
                    .footer-start {
                        .add-row {
                            color: var(--app-secondary-color);
                            background-color: transparent;
                            border: none;
                            box-shadow: none;
                        }

                        .total-hrs {}
                    }

                    .footer-end {
                        .close-btn {
                            color: var(--app-secondary-color);
                            background-color: transparent;
                            border: none;
                            box-shadow: none;
                        }
                    }
                }
            }
        }

        @media only screen and (max-width: 480px) {
            .custom-table {
                .tbl-header {
                    display: none;
                }

                .tbl-row {
                    width: 100%;

                    .tbl-date {
                        [class^="input-col"] {
                            padding-right: 0;
                        }

                        display: block !important;
                        text-align: center;

                        .d-flex {
                            display: block !important;

                            .error {
                                border: 1px solid red;
                            }

                            .input-col-Date {
                                width: 100%;
                                display: none;
                            }

                            .input-col-Hours {
                                width: 100%;
                                padding: 3px;

                                .label {
                                    display: block;
                                    text-align: center;
                                    font-weight: 600;
                                    margin-bottom: 8px;
                                    margin-top: 8px;
                                }

                                .form-control {
                                    width: 150px;
                                    margin: auto;
                                    text-align: center;
                                    font-size: 36px;
                                    margin-bottom: 10px;
                                }

                                input::-webkit-outer-spin-button,
                                input::-webkit-inner-spin-button {
                                    -webkit-appearance: none;
                                    margin: 0;
                                }

                                /* Firefox */
                                input[type=number] {
                                    -moz-appearance: textfield;
                                }
                            }

                            .input-col-Project {
                                width: 100%;
                                padding: 3px;

                                .label {
                                    display: block;
                                    text-align: center;
                                    font-weight: 600;
                                    margin-bottom: 8px;
                                    margin-top: 8px;
                                }

                                .form-select {
                                    text-overflow: ellipsis;
                                    overflow: hidden;
                                    white-space: nowrap;
                                    padding: 13px 16px !important;
                                    font-size: 14px;
                                    border: 1px solid #ced4da;
                                    border-radius: 4px;
                                    box-shadow: 0px 0px 6px rgb(0 0 0 / 4%);
                                    border-color: #dedede;
                                }
                            }

                            .input-col-Category {
                                width: 100%;
                                padding: 3px;

                                .label {
                                    display: block;
                                    text-align: center;
                                    font-weight: 600;
                                    margin-bottom: 8px;
                                    margin-top: 8px;
                                }

                                .form-select {
                                    text-overflow: ellipsis;
                                    overflow: hidden;
                                    white-space: nowrap;
                                    padding: 13px 16px !important;
                                    font-size: 14px;
                                    border: 1px solid #ced4da;
                                    border-radius: 4px;
                                    box-shadow: 0px 0px 6px rgb(0 0 0 / 4%);
                                    border-color: #dedede;
                                }
                            }

                            .input-col-Function {
                                width: 100%;
                                padding: 3px;

                                .label {
                                    display: block;
                                    text-align: center;
                                    font-weight: 600;
                                    margin-bottom: 8px;
                                    margin-top: 8px;
                                }

                                .form-select {
                                    text-overflow: ellipsis;
                                    overflow: hidden !important;
                                    white-space: nowrap;
                                    padding: 13px 16px !important;
                                    font-size: 14px;
                                    border: 1px solid #ced4da;
                                    border-radius: 4px;
                                    box-shadow: 0px 0px 6px rgb(0 0 0 / 4%);
                                    border-color: #dedede;
                                }

                                .form-control {
                                    .css-1rhw8h9-control {
                                        margin-top: 0;
                                    }

                                    .css-8iba47-control {
                                        margin-top: 0;
                                    }
                                }

                            }

                            .input-col-Discipline {
                                width: 100%;
                                padding: 3px;

                                .label {
                                    display: block;
                                    text-align: center;
                                    font-weight: 600;
                                    margin-bottom: 8px;
                                    margin-top: 8px;
                                }

                                .form-select {
                                    text-overflow: ellipsis;
                                    overflow: hidden;
                                    white-space: nowrap;
                                    padding: 13px 16px !important;
                                    font-size: 14px;
                                    border: 1px solid #ced4da;
                                    border-radius: 4px;
                                    box-shadow: 0px 0px 6px rgb(0 0 0 / 4%);
                                    border-color: #dedede;
                                }
                            }

                            .input-col-Comment {
                                width: 100%;
                                padding: 3px;

                                .label {
                                    display: block;
                                    text-align: center;
                                    font-weight: 600;
                                    margin-bottom: 8px;
                                    margin-top: 8px;
                                }

                                .form-control {
                                    padding: 13px 16px !important;
                                    font-size: 14px;
                                    border: 1px solid #ced4da;
                                    border-radius: 4px;
                                    box-shadow: 0px 0px 6px rgb(0 0 0 / 4%);
                                    border-color: #dedede;
                                }
                            }
                        }

                        .icon-col-Delete {
                            width: 100%;
                            cursor: context-menu;
                            text-align: center !important;
                            display: flex;
                            justify-content: center;
                            padding: 3px;

                            vertical-align: top;
                            border-bottom: rgba(0, 0, 0, 0.2) solid 1px;
                            margin-bottom: 20px;
                            padding-bottom: 20px;
                            padding-top: 10px;

                            .delete-btn {
                                width: 45px;
                                height: 45px;
                                min-width: 45px;
                                background-color: white;
                                color: var(--app-theme-color);
                                cursor: pointer !important;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                            }

                            .delete-btn:hover {
                                background-color: var(--app-theme-color);
                                color: white;
                            }
                        }
                    }
                }

                .tbl-footer {
                    margin-top: 1.5rem;

                    .submit-btn {
                        width: 100%;
                        font-size: large;
                        color: white;
                        background-color: var(--app-secondary-color);
                        border: 1px solid var(--app-secondary-color);
                    }

                    .submit-btn:hover {
                        font-size: large;
                        color: white;
                        background-color: var(--app-theme-color);
                        border: 1px solid var(--app-theme-color);
                    }
                }
            }
        }
    }

    .submit-footer {
        font-size: 18px;
        position: -webkit-sticky;
        position: sticky;
        bottom: 0;
        background-color: white;
        padding: 0.75rem 2.5rem;

        .total-hours {
            span {
                font-size: 2em;
                color: var(--app-theme-color);
            }

            .line-sm {
                line-height: 1
            }

            .text-md {
                font-size: 16px;
                font-weight: 500
            }

            .text-sm {
                font-size: 12px;
                font-weight: 400
            }
        }

        .submit {
            .submit-btn {
                font-size: 18px;
                color: white;
                background-color: var(--app-secondary-color);
                border: 1px solid var(--app-secondary-color);
            }

            .submit-btn:hover {
                font-size: 18px;
                color: white;
                background-color: var(--app-theme-color);
                border: 1px solid var(--app-theme-color);
            }
        }
    }

    @media only screen and (max-width: 480px) {
        .submit-footer {
            padding: 0.75rem 2.5rem;

            .total-hours {
                span {
                    font-size: 2em;
                }

                .line-sm {
                    line-height: 1 !important;
                    height: fit-content;
                }
            }

            .save {
                .save-btn {
                    width: 130px !important;
                    min-width: 130px !important;
                    font-size: 18px;
                    color: white;
                    background-color: var(--app-secondary-color);
                    border: 1px solid var(--app-secondary-color);
                }

                .save-btn:hover {
                    width: 130px !important;
                    min-width: 130px !important;
                    font-size: 18px;
                    color: white;
                    background-color: var(--app-theme-color);
                    border: 1px solid var(--app-theme-color);
                }
            }

            .submit {
                .submit-btn {
                    width: auto !important;
                    min-width: auto !important;
                }
            }
        }
    }

    @media only screen and (min-width: 1200px) {
        .submit-footer {
            margin-left: 296px !important;
        }
    }

    // back-to-top
    @media only screen and (max-width: 480px) {
        .back-to-top {
            background: #fff;
            position: fixed;
            visibility: visible;
            opacity: 1;
            right: 15px;
            bottom: 80px;
            z-index: 99999;
            width: 40px;
            height: 40px;
            border-radius: 4px;
            transition: all 0.4s;
            cursor: pointer;
            border: 1px solid var(--app-theme-color);
            color: var(--app-theme-color);
            display: flex;
        }

        .back-to-top:hover {
            background-color: var(--app-theme-color);
            color: white;
        }
    }
}

@media screen and (min-width: 1200px) {
    .toggle-sidebar #submit-footer {
        margin-left: 0 !important;
    }
}