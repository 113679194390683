.notifications-main {
    margin-top: 70px;
    padding: 40px;

    .pagetitle h1 {
        font-size: 32px;
        font-weight: 400;
        color: var(--app-theme-color);
        margin-bottom: 20px;
    }

    .section {
        .save-btn {
            color: white;
            background-color: var(--app-secondary-color);
            border: 1px solid var(--app-secondary-color);
        }
    }
}