#main ::placeholder {
  color: #AEAEAE;
}

@media (min-width: 1200px) {
  .logo {
    width: 222px;
  }
}

.header {
  height: 70px;
  box-shadow: none;
  background-color: var(--app-theme-color);
  padding-left: 0;
  color: #fff;

  .header-inner {
    background: #fff;
    display: flex;
    height: 70px;
    padding-left: 20px;
    padding-top: 6px;
    padding-bottom: 6px;

    .logo img,
    .logo svg {
      max-height: 48px;
      // padding-left: 30px;
      padding: 30px;
      margin-top: -1px;
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
      color: var(--app-theme-color);
    }

    .toggle-sidebar-btn {
      color: var(--app-theme-color);
      margin-top: 0;
      font-size: 32px;
      padding-left: 10px;
      cursor: pointer;
    }
  }

  .header-nav {
    padding-right: 30px;

    .nav-item {
      .nav-profile {
        color: #fff;

        span {
          font-size: 14px;
          font-weight: 400;
          display: inline-block;

          span {
            font-weight: 600;
          }
        }

        .dropdown {
          width: 36px;
          height: 36px;
          display: inline-block;
          background: #F9F9F9;
          border: #EAEAEA solid 1px;
          border-radius: 4px;
          padding: 0px
        }

        .dropdown-toggle::after {
          display: none
        }
      }

      .dropdown-menu {
        .dropdown-item {
          cursor: pointer;
        }
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .header {
    background-color: #fff !important;

    .header-inner {
      background: transparent;
      padding-left: 10px;
      padding-top: 6px;
      padding-bottom: 6px;

      .toggle-sidebar-btn {
        color: var(--app-theme-color);
      }

      .d-flex {
        .logo {
          width: 160px;
          height: 66px;
          background-size: contain !important;
          // background-image: url("./img/CiSC.svg") no-repeat;

          svg {
            max-height: 48px;
            // padding-left: 30px;
            padding: 0 !important;
            margin-top: -1px;
            max-width: 100%;
            max-height: 100%;
            object-fit: contain;
            color: var(--app-theme-color);
          }
        }
      }
    }

    .header-date {
      display: none;
    }

    .header-nav {
      display: none;
    }
  }
}