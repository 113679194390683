.period-main {
    margin-top: 70px;
    padding: 40px;

    .pagetitle h1 {
        font-size: 32px;
        font-weight: 400;
        color: var(--app-theme-color);
    }

    .mb {
        margin-bottom: 40px;
    }

    .section {
        .add-section {
            .react-datepicker__tab-loop {
                .react-datepicker-popper {
                    .react-datepicker {
                        .react-datepicker__month-container {
                            .react-datepicker__monthPicker {
                                .react-datepicker__month-wrapper {
                                    .react-datepicker__month-text {
                                        padding: 0.5rem;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .add-btn {
                font-size: 18px;
                color: white;
                background-color: var(--app-secondary-color);
                border: 1px solid var(--app-secondary-color);
            }

            .add-btn:hover {
                font-size: 18px;
                color: white;
                background-color: var(--app-theme-color);
                border: 1px solid var(--app-theme-color);
            }
        }

        /** Table **/
        .tableCustom {

            thead {
                border-top: #ddd solid 1px
            }

            tr {

                th,
                td {
                    border-color: #ddd;
                    padding-top: 20px;
                    padding-bottom: 20px;
                    font-size: 14px;
                }
            }

            tbody tr:hover td {
                background: #F4F4F4 !Important
            }

            tbody {
                .buttons {
                    .edit-btn {
                        color: var(--app-theme-color);
                    }

                    .delete-btn {
                        color: var(--app-theme-color);
                    }
                }

                .btn-outline-success {
                    background-color: transparent;
                    border: 1px solid green;
                    color: green;
                }

                .btn-outline-success:hover {
                    background-color: green;
                    border: 1px solid green;
                    color: white;
                }

                .btn-outline-danger {
                    background-color: transparent;
                    border: 1px solid red;
                    color: red;
                }

                .btn-outline-danger:hover {
                    background-color: red;
                    border: 1px solid red;
                    color: white;
                }
            }

            thead tr th.center,
            tbody tr td.center {
                text-align: center
            }
        }
    }
}