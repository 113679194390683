@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&family=Roboto+Slab&display=swap');

.logo span {
	font-family: "Poppins", sans-serif;
}

h1,
h2,
h3,
h4,
h5 {
	font-family: 'Roboto Slab', serif !Important;
	color: var(--app-theme-color);
	font-weight: 400 !important
}

body {
	font-size: 14px
}

#main ::placeholder {
	color: #AEAEAE;
}

@media (min-width: 1200px) {
	.logo {
		width: 222px;
	}
}

.logo img {
	max-height: 48px;
	padding-left: 30px;
}

.header-nav .nav-profile>span {
	font-size: 14px;
	font-weight: 400;
	display: inline-block;
	margin-left: 12px;
	margin-right: 12px
}

.header-nav .nav-profile .dropdown {
	width: 36px;
	height: 36px;
	display: inline-block;
	background: #F9F9F9;
	border: #EAEAEA solid 1px;
	border-radius: 4px;
	padding: 0px
}

.header-nav .nav-profile .dropdown-toggle::after {
	display: none
}

/* .header-nav {
	padding-right: 30px
} */

/** Side bar **/

.sidebar {
	top: 70px;
	padding: 40px 20px 20px 20px;
}

.sidebar-nav .nav-item {
	margin-bottom: 0px;
	margin-left: 15px;
	margin-right: 15px
}

.sidebar-nav .nav-link {
	font-weight: 400
}

.sidebar-nav .nav-link.collapsed {
	color: #aeaeae;
	border-bottom: #eaeaea solid 1px;
	padding: 15px 0;
	font-size: 14px;
	font-weight: 400;
}

.sidebar-nav .nav-link.collapsed>i {
	-webkit-filter: grayscale(100%);
	filter: grayscale(100%);
	opacity: 0.4
}

.sidebar-nav .nav-link {
	color: var(--app-secondary-color);
	padding: 15px 0;
	background: transparent;
	border-bottom: #eaeaea solid 1px;
	font-size: 14px;
}

.sidebar-nav .nav-link>i {
	margin-right: 18px;
}

.sidebar-nav .nav-link:hover {
	color: var(--app-secondary-color);
	background: none;
}

.sidebar-nav .nav-link>i.m-0 {
	margin-right: 0px
}

.sidebar-nav li.nav-item:last-child .nav-link {
	border-bottom: 0
}

.sidebar-nav .nav-content {
	padding: 0;
}

.sidebar-nav .nav-content a {
	font-weight: 400;
	color: #aeaeae;
	padding: 15px 0 15px 53px;
	border-top: #eaeaea solid 1px;
}

.sidebar-nav .nav-content a:hover,
.sidebar-nav .nav-content a.active,
.sidebar-nav .nav-content a:focus {
	color: var(--app-secondary-color);
}

.pagination .page-item .page-link {
	padding: 11px 16px 12px;
	color: #aeaeae;
	border-color: #eaeaea;
	font-weight: 500;
	box-shadow: 0px 0px 2px rgb(0 0 0 / 6%);
}



.pagination .page-item.active .page-link {
	color: var(--app-secondary-color);
	background-color: #fff;
	border-color: #eaeaea;
}

.pagination .page-item.prev .page-link,
.pagination .page-item.next .page-link {
	padding: 4px;
	background: #F9F9F9;
	border-color: #EAEAEA;
}

.pagination .page-item.prev .page-link img {
	webkit-transform: rotate(90deg);
	transform: rotate(90deg);
}

.pagination .page-item.next .page-link img {
	webkit-transform: rotate(-90deg);
	transform: rotate(-90deg);
}

.pagination.calendar .page-item a.page-link {
	background: #F9F9F9;
	border-color: #EAEAEA;
}

.pagination.calendar .page-item div.page-link {
	padding: 12px 20px 11px;
	color: #000;
	font-weight: 400;
	font-size: 14px
}

.form-control:focus,
.form-select:focus,
.btn:focus,
button:focus {
	border-color: #ccc;
	box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset, 0px 0px 8px rgba(0, 0, 0, 0.08);
}

.btn-group-customtab .btn-lg {
	padding: 17px 25px;
	font-size: 16px;
	min-width: 180px;
	background: #F9F9F9;
	border-color: #eaeaea;
	color: #aeaeae;
	box-shadow: 0px 0px 4px rgb(0 0 0 / 4%);
}

.btn-group-customtab .btn-lg.active {
	background: #fff;
	color: #000000
}


.btn-group-customtab .btn-md {
	padding: 12px 25px;
	font-size: 14px;
	min-width: 140px;
	background: #F9F9F9;
	border-color: #eaeaea;
	color: #aeaeae;
	box-shadow: 0px 0px 4px rgb(0 0 0 / 4%);
}

.btn-group-customtab .btn-md.active {
	background: #fff;
	color: #000000
}



.form-label {
	font-weight: 500;
	color: #000
}

.form-check-input {
	margin-top: 0px;
}

.form-control,
.form-select {
	box-shadow: 0px 0px 6px rgb(0 0 0 / 4%);
	border-color: #dedede
}

.form-control:disabled,
.form-control[readonly] {
	background-color: #E2E2E2;
	color: #aeaeae
}

.form-label {
	margin-bottom: 0.7rem;
}

hr {
	margin: 0;
	opacity: 1;
	background: #E2E2E2
}


#userinfo .nav-profile img {
	border: #dedede solid 1px;
	width: 150px;
	height: 150px
}

#editinfo .nav-profile img {
	border: #dedede solid 1px;
	width: 150px;
	height: 150px
}

.tableActivities tbody tr td:first-child {
	width: 22%
}

.tableActivities tbody tr td:last-child button {
	opacity: 0.2
}

.tableActivities thead {
	border: none;
}

.tableActivities thead tr th,
.tableActivities tbody tr td {
	border: none;
	padding-top: 10px;
	padding-bottom: 10px;
}

.tableActivities tbody tr:last-child td:last-child button {
	opacity: 1
}

.tableActivities tbody tr:last-child:hover td {
	background-color: transparent !Important
}

.tableActivities tbody tr td .form2 {
	padding: 13px 6px;
	text-align: center;
	font-size: 16px;
	line-height: 1
}

.tableActivities tbody tr td span.label {
	display: none
}

/** **/

.tableVSL tbody tr td:first-child {
	width: 20%;
	min-width: 240px
}

.tableVSL thead {
	border: none;
}

.tableVSL thead tr th {
	border: none;
	padding-top: 10px;
	padding-bottom: 10px;
	padding-left: 4px;
	padding-right: 4px;
}

.tableVSL tbody tr td {
	border: none;
	padding-top: 4px;
	padding-bottom: 4px;
	padding-left: 4px;
	padding-right: 4px;
	min-width: 60px
}

.tableVSL thead tr th span {
	display: block
}

.tableVSL thead tr th span.week-count,
.tableVSL thead tr th span.week-count span {
	display: inline-block;
	font-family: 'Roboto Slab', serif !Important;
	font-size: 18px;
	font-weight: normal;
	color: var(--app-theme-color)
}

.tableVSL thead tr th span.week {
	text-transform: uppercase;
	font-size: 12px
}

.tableVSL thead tr th span.day {
	font-size: 11px;
	color: #aeaeae
}

.tableVSL tbody tr:last-child:hover td {
	background-color: transparent !Important
}

.tableVSL tbody tr:last-child td {
	padding-top: 10px;
	padding-bottom: 10px;
}

.tableVSL tbody tr td .form2 {
	padding: 13px 2px;
	text-align: center;
	font-size: 16px;
	line-height: 1;
}

.tableVSL tbody tr td span.label {
	display: none
}

.tableVSL tbody tr.disabled td:last-child button {
	display: none
}

.tableVSL tbody tr td input.form-control.badge2 {
	border-color: #dedede;
}

.tableVSL tbody tr td input.form-control.badge2.green {
	background: rgba(103, 172, 96, 0.4);
	border-color: #2B7E22;
	color: #2B7E22
}

.tableVSL tbody tr td input.form-control.badge2.red {
	background: rgba(213, 66, 66, 0.4);
	border-color: #D54242;
	color: #D54242
}

.tableVSL tbody tr td input.form-control.badge2.yellow {
	background: rgba(243, 167, 18, 0.4);
	border-color: #ED9D02;
	color: #ED9D02
}

.tableVSL tbody tr td .dateSec {
	display: none
}

/** **/

/* .tableHours tbody tr td:first-child {
	width: 40%;
	min-width: 240px
} */

.tableHours thead {
	border: none;
}

.tableHours thead tr th {
	border: none;
	padding-top: 10px;
	padding-bottom: 10px;
	padding-left: 2px;
	padding-right: 2px;
}

.tableHours tbody tr td {
	border: none;
	padding-top: 4px;
	padding-bottom: 4px;
	padding-left: 2px;
	padding-right: 2px;
	/* min-width: 60px */
}

/* .tableHours thead tr th:last-child,
.tableHours tbody tr td:last-child {
	padding-left: 10px;
	font-weight: 600
} */

.tableHours thead tr th span {
	display: block
}

.tableHours thead tr th span.week-count,
.tableHours thead tr th span.week-count span {
	display: inline-block;
	font-family: 'Roboto Slab', serif !Important;
	font-size: 18px;
	font-weight: normal;
	color: var(--app-theme-color)
}

.tableHours thead tr th span.week {
	text-transform: uppercase;
	font-size: 12px
}

.tableHours thead tr th span.day {
	font-size: 11px;
	color: #aeaeae
}

.tableHours tbody tr:last-child:hover td {
	background-color: transparent !Important
}

.tableHours tbody tr:last-child td {
	padding-top: 10px;
	padding-bottom: 10px;
	font-weight: 600
}

.tableHours tbody tr td .form2 {
	padding: 12px 2px;
	text-align: center;
	font-size: 16px;
	line-height: 1;
}

.tableHours tbody tr td span.label {
	display: none
}

.tableHours tbody tr td span.week {
	display: none
}



.vsl-badges {
	text-align: right
}

.vsl-badges .badge {
	background: #fff;
	font-size: 12px;
	color: #333;
	font-weight: 400;
	padding: 8px 16px 8px 8px;
}

.vsl-badges .badge>span {
	background: #ccc;
	border: #ccc solid 1px;
	width: 16px;
	height: 16px;
	display: inline-block;
	vertical-align: middle;
	margin-right: 6px;
	border-radius: 3px
}

.vsl-badges .badge.green>span {
	background: rgba(103, 172, 96, 0.4);
	border-color: #2B7E22;
}

.vsl-badges .badge.red>span {
	background: rgba(213, 66, 66, 0.4);
	border-color: #D54242;
}

.vsl-badges .badge.yellow>span {
	background: rgba(243, 167, 18, 0.4);
	border-color: #ED9D02;
}


.tableApproval tbody tr:first-child td {
	border-top: #ddd solid 1px;
}

.tableApproval tbody tr td:first-child {
	width: 40px
}

.tableApproval tbody tr td:nth-child(6) {
	width: 120px;
	padding-left: 20px
}

.tableApproval tbody tr td:last-child {
	width: 230px;
}


.tableApproval tbody tr.listing {
	display: none
}

.tableApproval .divApproval {
	margin: 10px 5px;
	min-width: 1600px
}

.tableApproval .tableHours thead tr th {
	border-top: 0px;
	padding-bottom: 5px
}

.tableApproval .tableHours tbody tr:first-child td {
	border-top: 0px
}


.tableApproval .tableHours tbody tr td {
	min-width: inherit !Important;
	width: auto;
	padding: 2px;
}

.tableApproval .tableHours .form-control {
	padding: 7px 4px;
	font-size: 14px;
}

.tableApproval .tableHours tbody tr td:first-child {
	width: auto;
	min-width: 180px !Important
}

.tableApproval .tableHours tbody tr td:nth-child(32) {
	width: 120px;
	padding-left: 20px
}

.tableApproval .tableHours tbody tr td:last-child {
	width: 230px;
	min-width: 230px
}


.tableApproval .tableHours tbody tr td:first-child .form-control {
	padding: 7px 12px;
	font-size: 14px;
}

.tableApproval .tableHours thead tr th:last-child,
.tableApproval .tableHours tbody tr td:last-child {
	padding-left: 0px;
}

.tableApproval .tableHours tbody tr:last-child td {
	font-weight: 400;
}

.tableApproval .tableHours thead tr th {
	text-align: center
}

.tableApproval .tableHours thead tr th:first-child {
	text-align: left
}

@media only screen and (max-width:1024px) {

	.tableActivities thead tr th,
	.tableActivities tbody tr td {
		padding-left: 1px;
		padding-right: 1px;
	}

	.tableActivities thead tr th:first-child,
	.tableActivities tbody tr td:first-child {
		padding-left: 0.5rem;
	}

	.tableActivities thead tr th:last-child,
	.tableActivities tbody tr td:last-child {
		padding-right: 0.5rem;
	}
}

@media only screen and (max-width: 1024px) {
	.date {
		display: none
	}
}

@media only screen and (max-width: 768px) {
	.logo {
		width: 222px;
	}

	.date {
		display: none
	}

	.tableProjects thead tr th:nth-child(2),
	.tableProjects tbody tr td:nth-child(2) {
		display: none
	}

	.tableProjects thead tr th:last-child,
	.tableProjects tbody tr td:last-child {
		width: 25%
	}

	.tableUsers tbody tr td:first-child {
		width: auto;
	}

	.tableUsers tbody tr td:first-child .nav-profile img {
		display: none
	}

	.tableUsers thead tr th:last-child,
	.tableUsers tbody tr td:last-child {
		width: 33%
	}

	.vsl-badges {
		text-align: left;
		margin-top: 10px
	}

	.btn.btn-icon2.ms-2.me-2 {
		margin-left: 0 !important
	}

	.titleSec>div:first-child {
		display: block !Important;
		margin-bottom: 20px
	}

	.tableApproval tbody tr td:first-child {
		width: 40px;
	}

	.tableApproval tbody tr td:last-child {
		width: 230px;
	}

	.tableApproval .divApproval {
		min-width: 1600px
	}

	.tableApproval>tbody tr td:nth-child(4),
	.tableApproval>tbody tr td:nth-child(5) {
		width: 10px !Important;
		color: transparent;
		font-size: 1px;
	}

	.tableApproval>tbody tr td:nth-child(5) span {
		display: none
	}

	.tableApproval .tableHours .form-control {
		min-width: 32px
	}


}

@media only screen and (max-width: 480px) {
	#main {
		margin-top: 70px;
		padding: 20px;
	}

	.btn-icon img {
		width: 95%
	}

	.sidebar {
		padding-top: 20px;
	}


	.btm-bar {
		margin-top: 20px
	}

	.btm-bar .pagination {
		display: none
	}

	.btm-bar .btn {
		width: 100%
	}

	.btm-bar div.textmd.textgray {
		display: none !Important;
	}

	.tableProjects thead tr th,
	.tableProjects tbody tr td,
	.tableUsers thead tr th {
		display: none;
		border: 0;
	}

	.tableProjects tbody tr,
	.tableUsers tbody tr {
		border-bottom: #ddd solid 1px;
	}

	.tableProjects tbody tr td:first-child,
	.tableProjects tbody tr td:nth-child(3),
	.tableProjects tbody tr td:last-child {
		display: inline-block;
	}

	.tableProjects tbody tr td:first-child {
		width: 100%;
		font-size: 18px;
		padding-top: 20px;
		padding-bottom: 0
	}

	.tableProjects tbody tr td:nth-child(3),
	.tableProjects tbody tr td:last-child {
		width: 50%;
		padding-bottom: 20px
	}

	.tableUsers tbody tr td {
		display: inline-block;
		border: 0;
		width: 33.33%;
		padding-bottom: 0
	}

	.tableUsers tbody tr td:first-child {
		width: 100%;
		font-size: 18px;
		padding-top: 20px;
		padding-bottom: 0
	}

	.tableUsers tbody tr td:last-child {
		width: 100%;
		padding-bottom: 20px;
		text-align: left
	}

	.tableUsers tbody tr td:last-child button:last-child {
		width: 70%;
	}

	.modal-dialog .modal-content {
		padding: 30px;
	}

	.modal-dialog .modal-footer button {
		width: 50%
	}

	.tableActivities thead tr {
		display: none
	}

	.tableActivities tbody tr td span.label {
		display: block;
		font-weight: 500;
		margin-bottom: 4px
	}

	.tableActivities tbody tr td {
		display: inline-block;
		width: 16.6%;
		padding-left: 0 !Important;
		padding-right: 0
	}

	.tableActivities tbody tr td:first-child {
		width: 100%
	}

	.btn-group-customtab {
		width: 100%
	}

	.btn-group-customtab .btn-lg {
		padding: 17px 25px;
		font-size: 16px;
		min-width: inherit;
		width: 33.3%
	}

	.vsl-badges {
		text-align: left;
		margin-top: 15px
	}

	.leave-info>div {
		margin: 20px 0 30px
	}


	.tableHours tbody tr td span.week {
		display: block;
		font-size: 12px;
		font-weight: 500;
		padding-bottom: 5px;
		color: #aeaeae;
	}

	.tableHours thead tr th {
		display: none
	}

	.tableHours tbody tr td {
		display: inline-block;
		width: 14.25%;
		min-width: 14.25%;
	}

	.tableHours tbody tr td:first-child {
		width: 100%;
		margin-top: 30px;
		padding-top: 30px;
		border-top: #E2E2E2 solid 1px;
		margin-bottom: 10px
	}

	.tableHours tbody tr td:first-child input {
		padding-top: 20px;
		padding-bottom: 20px;
	}

	.tableHours tbody tr:first-child td:first-child {
		width: 100%;
		margin-top: 0px;
		padding-top: 0px;
		border-top: 0px
	}

	.tableHours tbody tr:last-child td {
		display: none
	}

	.tableHours tbody tr:last-child td:first-child {
		display: block
	}

	.tableHours tbody tr td:last-child {
		font-size: 18px;
		padding-top: 20px
	}

	.titleSec {
		margin-bottom: 20px
	}

	.tableApproval tbody tr td:first-child {
		width: 15%;
		padding-bottom: 20px;
		display: none
	}

	.tableApproval tbody tr td:nth-child(2) {
		width: 100%;
		padding-bottom: 10px;
		font-size: 18px;
		top: 30px
	}

	.tableApproval tbody tr td:nth-child(3),
	.tableApproval tbody tr td:nth-child(6) {
		width: 50%;
		border-top: 0px;
		padding-top: 0px
	}

	.tableApproval tbody tr td:nth-child(4),
	.tableApproval tbody tr td:nth-child(5) {
		display: none
	}

	.tableApproval tbody tr td:last-child {
		width: 100%;
		border-top: 0px;
		padding-bottom: 30px
	}

	.tableApproval tbody tr td:last-child button {
		width: 48% !important;
	}
}