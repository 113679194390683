.new-timesheet {
    .timesheet-main {
        margin-top: 70px;
        padding: 40px;

        .pagetitle {
            margin-bottom: 40px;

            .pagetitle h1 {
                font-size: 24px;
                margin-bottom: 0;
                font-weight: 600;
                color: var(--app-theme-color);
            }
        }

        @media only screen and (max-width: 480px) {
            .pagetitle {
                text-align: center;

                .pagetitle h1 {
                    font-size: 20px;
                }
            }
        }

        .calendar-header {
            display: flex;
            align-items: center;
            gap: 1rem;

            .calendar {
                width: auto;
                background-color: white;
                border: 1px solid #ced4da;
                border-radius: 5px;

                .fa-chevron-left,
                .fa-chevron-right {
                    color: var(--app-theme-color);
                }

                .btn-icon-left,
                .btn-icon-right {
                    background-color: #ced4da !important;
                    width: 2.5rem;
                    height: 2.5rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    box-shadow: none;
                    border: none;

                    i {
                        color: var(--app-theme-color);
                    }
                }

                .btn-icon-left {
                    border-right: 1px solid #ced4da;
                }

                .btn-icon-right {
                    border-left: 1px solid #ced4da;
                }
            }

            .cal-icon {
                .cal-btn {
                    background-color: white !important;
                    border: 1px solid #ced4da;
                    width: 2.5rem;
                    height: 2.5rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    i {
                        color: var(--app-theme-color);
                    }
                }

                .cal-btn:active {
                    border: 1px solid #ced4da;
                }
            }

            .react-datepicker__input-container {
                display: block;
            }

            .user-select {
                width: 200px;
                max-width: 200px;
                min-width: 200px;
            }

            .copy-btn {
                button {
                    background-color: white;
                    color: var(--app-theme-color);
                    border: 1px solid #ced4da;
                }

                button:hover {
                    // background-color: var(--app-theme-color);
                    // color: white;
                    border: 1px solid #ced4da;
                    box-shadow: none;
                }
            }

            .import-btn {
                button {
                    background-color: var(--app-theme-color);
                    border: none;
                }

                button:hover {
                    background-color: var(--app-secondary-color);
                    border: none;
                }
            }
        }

        @media only screen and (max-width: 480px) {
            .calendar-header {
                justify-content: center;

                .calendar {
                    background-color: transparent;
                    border: none;

                    .btn-icon-left,
                    .btn-icon-right {
                        border-radius: 50% !important;
                        height: 51.6px;
                        width: 51.6px;
                        background-color: #F9F9F9 !important;
                        border: 1px solid #EAEAEA;
                    }

                    h6 {
                        font-size: 18px;
                        background: transparent;
                        box-shadow: none;
                        font-weight: 500 !important;
                    }
                }

                .react-datepicker-wrapper {
                    display: none;
                }
            }
        }

        .custom-table {
            .week-header {
                display: inline-block;
                font-family: 'Roboto Slab', serif ! Important;
                font-size: 18px;
                font-weight: normal;
                color: var(--app-theme-color);
            }

            .tableCustom {
                .table-header {}

                .table-body {
                    .table-row {

                        .td-copy,
                        .td-favorite,
                        .td-delete {

                            .btn {
                                background-color: white;
                                padding-left: 0;
                                padding-right: 0;
                                width: 35px;

                                i {
                                    color: var(--app-theme-color);
                                }
                            }

                            .btn:hover {
                                background-color: var(--app-theme-color);

                                i {
                                    color: white;
                                }
                            }
                        }

                        .td-btn {
                            width: 40% !important;
                            min-width: 240px !important;

                            .btn-form-modal {
                                padding: 4px 2px !important;
                                background: white;
                                border: 1px;
                                min-height: 51px;

                                .project-display {
                                    font-size: 13px;
                                    font-weight: 600 !important;
                                }

                                .comments-display {
                                    font-size: 11px;
                                    line-height: 11px;
                                    display: -webkit-box;
                                    -webkit-line-clamp: 2;
                                    -webkit-box-orient: vertical;
                                    overflow: hidden;
                                }
                            }
                        }

                        .td-time-input {
                            position: relative;

                            .hour-notes {
                                position: absolute;
                                top: 0.25rem;
                                right: 0.5rem;

                                i {
                                    font-size: 11px;
                                    color: var(--app-theme-color);
                                }
                            }

                            .holiday-div {
                                .holiday {
                                    .holiday-input-disable {
                                        height: 51px;
                                        cursor: pointer;
                                        background-color: #e2e2e2;
                                        pointer-events: none;
                                    }
                                }

                                .holiday-disable {
                                    pointer-events: none;

                                    .holiday-input-disable {
                                        height: 51px;
                                        cursor: pointer;
                                        background-color: #e2e2e2;
                                        pointer-events: none;
                                    }
                                }
                            }

                            .error {
                                border: 1px solid red !important;
                            }
                        }

                        .add-new-row {
                            .add-row {
                                color: #FF5F00;
                                background: transparent;
                                box-shadow: none;
                                border: 0;
                                padding: 0;
                                text-decoration: none;
                                border-radius: 4px;
                                font-weight: 500;
                                font-size: 14px;
                                min-width: 100px;
                            }
                        }
                    }
                }
            }

        }

        @media only screen and (max-width: 480px) {
            .custom-table {
                .tbl-header {
                    display: none;
                }

                .tbl-row {
                    width: 100%;

                    .tbl-date {
                        [class^="input-col"] {
                            padding-right: 0;
                        }

                        display: block !important;
                        text-align: center;

                        .d-flex {
                            display: block !important;

                            .error {
                                border: 1px solid red;
                            }

                            .input-col-Date {
                                width: 100%;
                                display: none;
                            }

                            .input-col-Hours {
                                width: 100%;
                                padding: 3px;

                                .label {
                                    display: block;
                                    text-align: center;
                                    font-weight: 600;
                                    margin-bottom: 8px;
                                    margin-top: 8px;
                                }

                                .form-control {
                                    width: 150px;
                                    margin: auto;
                                    text-align: center;
                                    font-size: 36px;
                                    margin-bottom: 10px;
                                }

                                input::-webkit-outer-spin-button,
                                input::-webkit-inner-spin-button {
                                    -webkit-appearance: none;
                                    margin: 0;
                                }

                                /* Firefox */
                                input[type=number] {
                                    -moz-appearance: textfield;
                                }
                            }

                            .input-col-Project {
                                width: 100%;
                                padding: 3px;

                                .label {
                                    display: block;
                                    text-align: center;
                                    font-weight: 600;
                                    margin-bottom: 8px;
                                    margin-top: 8px;
                                }

                                .form-select {
                                    text-overflow: ellipsis;
                                    overflow: hidden;
                                    white-space: nowrap;
                                    padding: 13px 16px !important;
                                    font-size: 14px;
                                    border: 1px solid #ced4da;
                                    border-radius: 4px;
                                    box-shadow: 0px 0px 6px rgb(0 0 0 / 4%);
                                    border-color: #dedede;
                                }
                            }

                            .input-col-Category {
                                width: 100%;
                                padding: 3px;

                                .label {
                                    display: block;
                                    text-align: center;
                                    font-weight: 600;
                                    margin-bottom: 8px;
                                    margin-top: 8px;
                                }

                                .form-select {
                                    text-overflow: ellipsis;
                                    overflow: hidden;
                                    white-space: nowrap;
                                    padding: 13px 16px !important;
                                    font-size: 14px;
                                    border: 1px solid #ced4da;
                                    border-radius: 4px;
                                    box-shadow: 0px 0px 6px rgb(0 0 0 / 4%);
                                    border-color: #dedede;
                                }
                            }

                            .input-col-Function {
                                width: 100%;
                                padding: 3px;

                                .label {
                                    display: block;
                                    text-align: center;
                                    font-weight: 600;
                                    margin-bottom: 8px;
                                    margin-top: 8px;
                                }

                                .form-select {
                                    text-overflow: ellipsis;
                                    overflow: hidden !important;
                                    white-space: nowrap;
                                    padding: 13px 16px !important;
                                    font-size: 14px;
                                    border: 1px solid #ced4da;
                                    border-radius: 4px;
                                    box-shadow: 0px 0px 6px rgb(0 0 0 / 4%);
                                    border-color: #dedede;
                                }

                                .form-control {
                                    .css-1rhw8h9-control {
                                        margin-top: 0;
                                    }

                                    .css-8iba47-control {
                                        margin-top: 0;
                                    }
                                }

                            }

                            .input-col-Discipline {
                                width: 100%;
                                padding: 3px;

                                .label {
                                    display: block;
                                    text-align: center;
                                    font-weight: 600;
                                    margin-bottom: 8px;
                                    margin-top: 8px;
                                }

                                .form-select {
                                    text-overflow: ellipsis;
                                    overflow: hidden;
                                    white-space: nowrap;
                                    padding: 13px 16px !important;
                                    font-size: 14px;
                                    border: 1px solid #ced4da;
                                    border-radius: 4px;
                                    box-shadow: 0px 0px 6px rgb(0 0 0 / 4%);
                                    border-color: #dedede;
                                }
                            }

                            .input-col-Comment {
                                width: 100%;
                                padding: 3px;

                                .label {
                                    display: block;
                                    text-align: center;
                                    font-weight: 600;
                                    margin-bottom: 8px;
                                    margin-top: 8px;
                                }

                                .form-control {
                                    padding: 13px 16px !important;
                                    font-size: 14px;
                                    border: 1px solid #ced4da;
                                    border-radius: 4px;
                                    box-shadow: 0px 0px 6px rgb(0 0 0 / 4%);
                                    border-color: #dedede;
                                }
                            }
                        }

                        .icon-col-Delete {
                            width: 100%;
                            cursor: context-menu;
                            text-align: center !important;
                            display: flex;
                            justify-content: center;
                            padding: 3px;

                            vertical-align: top;
                            border-bottom: rgba(0, 0, 0, 0.2) solid 1px;
                            margin-bottom: 20px;
                            padding-bottom: 20px;
                            padding-top: 10px;

                            .delete-btn {
                                width: 45px;
                                height: 45px;
                                min-width: 45px;
                                background-color: white;
                                color: var(--app-theme-color);
                                cursor: pointer !important;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                            }

                            .delete-btn:hover {
                                background-color: var(--app-theme-color);
                                color: white;
                            }
                        }
                    }
                }

                .tbl-footer {
                    margin-top: 1.5rem;

                    .submit-btn {
                        width: 100%;
                        font-size: large;
                        color: white;
                        background-color: var(--app-secondary-color);
                        border: 1px solid var(--app-secondary-color);
                    }

                    .submit-btn:hover {
                        font-size: large;
                        color: white;
                        background-color: var(--app-theme-color);
                        border: 1px solid var(--app-theme-color);
                    }
                }
            }
        }
    }

    .submit-footer {
        font-size: 18px;
        position: -webkit-sticky;
        position: sticky;
        // bottom: 0;
        // background-color: white;
        padding: 0.75rem 2.5rem;

        .total-hours {
            span {
                font-size: 2em;
                color: var(--app-theme-color);
            }

            .line-sm {
                line-height: 1
            }

            .text-md {
                font-size: 16px;
                font-weight: 500
            }

            .text-sm {
                font-size: 12px;
                font-weight: 400
            }
        }

        .submit {
            .submit-btn {
                font-size: 18px;
                color: white;
                background-color: var(--app-secondary-color);
                border: 1px solid var(--app-secondary-color);
            }

            .submit-btn:hover {
                font-size: 18px;
                color: white;
                background-color: var(--app-theme-color);
                border: 1px solid var(--app-theme-color);
            }
        }
    }

    /** modal **/
    .modal-dialog {
        .modal-content {
            border-radius: 8px;
            box-shadow: 0px 0px 12px rgb(0 0 0 / 20%);
            padding: 40px;

            hr {
                opacity: 0.25;
                background: #E2E2E2 !important;
            }

            .modal-header {
                padding: 0 0 40px 0;
                border: 0;

                .modal-title {
                    font-size: 26px
                }

                .btn-close {
                    font-size: 48px;
                    color: #aeaeae
                }
            }

            .modal-body {
                padding: 0px;

                .style-4::-webkit-scrollbar-track {
                    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
                    background-color: #F5F5F5;
                }

                .style-4::-webkit-scrollbar {
                    width: 30px;
                    background-color: #F5F5F5;
                }

                .style-4::-webkit-scrollbar-thumb {
                    background-color: #000000;
                    border: none;
                }


                tr {
                    .customTd {
                        min-width: 32vw;
                    }
                }

                .calendar-header {
                    display: flex;
                    align-items: center;
                    gap: 1rem;

                    .calendar {
                        width: auto;
                        background-color: white;
                        border: 1px solid #ced4da;
                        border-radius: 5px;

                        .fa-chevron-left,
                        .fa-chevron-right {
                            color: var(--app-theme-color);
                        }

                        .btn-icon-left,
                        .btn-icon-right {
                            background-color: #ced4da !important;
                            width: 2.5rem;
                            height: 2.5rem;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            box-shadow: none;
                            border: none;

                            i {
                                color: var(--app-theme-color);
                            }
                        }

                        .btn-icon-left {
                            border-right: 1px solid #ced4da;
                        }

                        .btn-icon-right {
                            border-left: 1px solid #ced4da;
                        }
                    }

                    .calendar-btn {
                        .cal-icon {
                            .cal-btn {
                                background-color: white !important;
                                border: 1px solid #ced4da;
                                width: 2.5rem;
                                height: 2.5rem;
                                display: flex;
                                align-items: center;
                                justify-content: center;

                                i {
                                    color: var(--app-theme-color);
                                }
                            }

                            .cal-btn:active {
                                border: 1px solid #ced4da;
                            }
                        }

                        .react-datepicker__input-container {
                            display: block;
                        }
                    }

                    .user-select {
                        width: 200px;
                        max-width: 200px;
                        min-width: 200px;
                    }

                    .import-btn {
                        button {
                            background-color: var(--app-theme-color);
                            border: none;
                        }

                        button:hover {
                            background-color: var(--app-secondary-color);
                            border: none;
                        }
                    }
                }
            }

            .modal-footer {
                padding: 40px 0px 0px 0px;
                border: 0;

                button {
                    margin: 0;
                }

                .btn-full {
                    width: 100% ! Important;
                }

                .submit-btn {
                    background-color: var(--app-secondary-color);
                    color: white;
                }

                .submit-btn:hover {
                    background-color: var(--app-theme-color);
                    color: white;
                }

                .cancel-btn {
                    color: black;
                    box-shadow: none;
                }

                .cancel-btn:hover {
                    background-color: var(--app-theme-color);
                    color: white;
                }
            }
        }
    }

    // copy modal
    .copymodal {
        .modal-dialog {
            display: flex;
            height: auto;
            margin: auto;
            max-width: 100%;
            margin-right: 0;
            margin-left: 0;

            .modal-custom-content {
                width: auto;
                border-radius: 8px;
                box-shadow: 0px 0px 12px rgb(0 0 0 / 20%);
                padding: 40px;

                hr {
                    opacity: 0.25;
                    background: #E2E2E2 !important;
                }

                .modal-custom-header {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    border: 0;

                    .modal-title {
                        font-size: 26px;
                    }

                    .btn-close {
                        margin: auto -7px 0px auto;
                        font-size: 54px;
                        color: #aeaeae;
                        padding: 17px 0px 0px 0px;
                    }
                }

                .modal-body {
                    width: 85vw;
                    padding: 0px;

                    .table>:not(caption)>*>* {
                        background-color: #F7F9FC;
                    }

                    tr {
                        td:first-child {
                            width: 42px;
                        }

                        .customTd {
                            min-width: 30vw;
                        }
                    }

                    .calendar-header {
                        display: flex;
                        align-items: center;
                        gap: 1rem;

                        .calendar {
                            width: auto;
                            background-color: white;
                            border: 1px solid #ced4da !important;
                            border-radius: 5px;

                            .fa-chevron-left,
                            .fa-chevron-right {
                                color: var(--app-theme-color);
                            }

                            .btn-icon-left,
                            .btn-icon-right {
                                background-color: #ced4da !important;
                                width: 2.5rem;
                                height: 2.5rem;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                box-shadow: none;
                                border: none;

                                i {
                                    color: var(--app-theme-color);
                                }
                            }

                            .btn-icon-left {
                                border-right: 1px solid #ced4da;
                                border-top-right-radius: 0;
                                border-bottom-right-radius: 0;
                            }

                            .btn-icon-right {
                                border-left: 1px solid #ced4da;
                                border-top-left-radius: 0;
                                border-bottom-left-radius: 0;
                            }
                        }

                        .cal-icon {
                            .cal-btn {
                                background-color: white !important;
                                border: 1px solid #ced4da;
                                width: 2.5rem;
                                height: 2.5rem;
                                display: flex;
                                align-items: center;
                                justify-content: center;

                                i {
                                    color: var(--app-theme-color);
                                }
                            }

                            .cal-btn:active {
                                border: 1px solid #ced4da;
                            }
                        }

                        .react-datepicker__input-container {
                            display: block;
                        }

                        .user-select {
                            width: 200px;
                            max-width: 200px;
                            min-width: 200px;
                        }

                        .import-btn {
                            button {
                                background-color: var(--app-theme-color);
                                border: none;
                            }

                            button:hover {
                                background-color: var(--app-secondary-color);
                                border: none;
                            }
                        }
                    }

                    .custom-table {
                        .week-header {
                            display: inline-block;
                            font-family: "Roboto Slab", serif !important;
                            font-size: 18px;
                            font-weight: normal;
                            color: var(--app-theme-color);
                        }

                        .tableCustom {
                            .table-body {
                                .table-row {
                                    .td-copy {
                                        .form-check-input {
                                            border-radius: -0.75em;
                                            font-size: 10px;
                                        }

                                        input {
                                            box-shadow: none;
                                            border: 1px solid #ced4da;
                                        }

                                        input:checked {
                                            background-color: var(--app-secondary-color);
                                            box-shadow: none;
                                            border: none;
                                        }
                                    }

                                    .btn-form-modal {
                                        padding: 4px 2px !important;
                                        background: white;
                                        border: 1px;
                                        min-height: 51px;
                                        border: 1px solid #ced4da;

                                        .project-display {
                                            font-size: 13px;
                                            font-weight: 600 !important;
                                        }

                                        .comments-display {
                                            font-size: 11px;
                                            line-height: 11px;
                                            display: -webkit-box;
                                            -webkit-line-clamp: 2;
                                            -webkit-box-orient: vertical;
                                            overflow: hidden;
                                        }
                                    }

                                    .td-time-input {
                                        .hour-div {
                                            padding: 4px 2px !important;
                                            background: white;
                                            border: 1px;
                                            border-radius: 4px;
                                            min-height: 51px;
                                            border: 1px solid #ced4da;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .modal-footer {
                    padding: 20px 0px 0px 0px;
                    border: 0;

                    button {
                        margin: 0;
                    }

                    .btn-full {
                        width: 100% ! Important;
                    }

                    .copy-btn {
                        background-color: var(--app-secondary-color);
                        color: white;
                        border: 1px solid var(--app-secondary-color);
                    }

                    .copy-btn:hover {
                        background-color: var(--app-theme-color);
                        color: white;
                        border: 1px solid var(--app-theme-color);
                    }

                    .cancel-btn {
                        color: black;
                        box-shadow: none;
                    }

                    .cancel-btn:hover {
                        background-color: var(--app-theme-color);
                        color: white;
                    }
                }
            }
        }
    }

    // back-to-top
    @media only screen and (max-width: 480px) {
        .back-to-top {
            background: #fff;
            position: fixed;
            visibility: visible;
            opacity: 1;
            right: 15px;
            bottom: 80px;
            z-index: 99999;
            width: 40px;
            height: 40px;
            border-radius: 4px;
            transition: all 0.4s;
            cursor: pointer;
            border: 1px solid var(--app-theme-color);
            color: var(--app-theme-color);
            display: flex;
        }

        .back-to-top:hover {
            background-color: var(--app-theme-color);
            color: white;
        }
    }
}

@media screen and (min-width: 1200px) {
    .toggle-sidebar #submit-footer {
        margin-left: 0 !important;
    }
}

@media only screen and (min-width: 1200px) {
    .new-timesheet .submit-footer {
        margin-left: 296px !important;
    }
}